import { LitElement, html, css } from "lit-element";
import icon_ringe from "../img/icon_ringe.svg";
import icon_torte from "../img/icon_torte.svg";

class YJLandingPage extends LitElement {
  static get styles() {
    return css`
      * {
        box-sizing: border-box;
      }

      h2{
        font-size: 1.1em;
      }

      #container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      .location {
        margin: 50px;
      }

      .location > img {
        height: 50px;
      }

      .location > address {
        font-style: normal;
        margin-top: 20px;
      }

      /***** Title *****/
      #title h1 {
        font-family: "Honeymoon Avenue Script Demo", cursive;
        margin: 0;
        font-size: 8em;
        font-weight: lighter;
      }

      hr {
        border: none;
        height: 1px;
        color: black;
        background-color: black;
      }

      #title span {
        display: inline-block;
        margin: 0.5em;
        font-size: 2em;
        font-weight: 300;
        font-family: "Josefin Sans";
      }

      #title span.old{
        font-size: 1em;
        text-decoration: line-through; 
      }

      @media all and (min-device-width: 800px) {
        #container {
          width: 100%;
        }

        .arrow {
          width: 30px;
          height: 30px;
          display: block;
          position: relative;
          margin: 0px auto 100px auto;

          border: 2px solid transparent;
          border-bottom-color: black;
          border-right-color: black;
          border-radius: 0 0 4px 0;

          bottom: 0px;
          transform: rotate(45deg);
          transition: all 0.2s ease-in-out;
        }

        .arrow:hover {
          cursor: pointer;
          bottom: -10px;
        }

      }

      @media all and (max-device-width: 799px) {
        /* Double box so we can set this to 100vh on mobile */
        #title {
          display: none;
        }

        .location {
          width: 100%;
        }
      }
    `;
  }

  _handleArrowClick() {
    window.location.hash = "info";
  }

  render() {
    return html`
      <div id="container">
        <div id="title">
          <h1>Yvonne und Johannes</h1>
          <hr />
          <span class="old">Nördlingen 19.06.2021</span><br />
          <span>Nördlingen 18.06.2022</span>
        </div>
        <div style="width:100%"></div>
        <div class="location">
          <img src="${icon_ringe}" alt="Trauung"/>
          <h2>TRAUUNG & SEKTEMPFANG</h2>
          <span class="date">18.06.2022<br /></span>
          <span class="time">14:00 Uhr - 16:00 Uhr<br /></span>
          <address>
            Jakobuskirche<br />
            Langestraße 23<br />
            73467 Kirchheim am Ries
          </address>
        </div>
        <div class="location">
          <img src="${icon_torte}" alt="Feier" />
          <h2>HOCHZEITSFEIER</h2>
          <span class="date">18.06.2022<br /></span>
          <span class="time">16:00 Uhr - 03:00 Uhr<br /></span>
          <address>
            2nd Home Hotel<br />
            Luntenbuck 9<br />
            86720 Nördlingen
          </address>
        </div>
        <div style="width:100%"></div>
        <div class="arrow" @click="${this._handleArrowClick}"></div>
      </div>
    `;
  }
}
customElements.define("yj-landing-page", YJLandingPage);
