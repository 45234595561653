import { LitElement, html, css } from "lit-element";

class YJImpressum extends LitElement {
  static get styles() {
    return css`
      address {
        font-style: normal;
      }

      a {
        color: var(--theme-color);
        text-decoration: underline;
      }

      a:hover {
        color: var(--theme-color);
        text-decoration: none;
      }
    `;
  }

  render() {
    return html`
      <h2>Impressum</h2>

      Angaben gemäß § 5 TMG<br /><br />

      <address>
        Johannes Stählin<br />
        Würzburger Str. 15<br />
        86720 Nördlingen<br />
        <br />
        johannes.staehlin@gmail.com
      </address>

      <h2>Bildnachweis</h2>
      Hotel:
      <a href="https://www.noerdlingen.de/">Stadt Nördlingen</a><br />
      Spiele & Mehr:
      <a href="https://unsplash.com/@adigold1">Adi Goldstein</a><br />
      Geschenke:
      <a href="Wijdan Mq">Wijdan Mq</a><br />
      Parken:
      <a href="https://unsplash.com/@georgiadelotz">Georgia de Lotz</a><br />
      Corona:
      <a href="https://unsplash.com/@anshu18">Anshu A</a><br />
      Kirche & Sektempfang:
      <a href="https://unsplash.com/@redaquamedia">Denny Müller</a><br />
      Anfahrt:
      <a href="https://unsplash.com/@danielcgold">Dan Gold</a><br />
    `;
  }
}
customElements.define("yj-impressum", YJImpressum);
