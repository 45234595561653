
function route(){
    console.log(location.hash)
    
    var content = document.getElementById("content");
    var impressum = document.getElementById("impressum");
    var dataprivacy = document.getElementById("dataprivacy");

    content.style.display = location.hash !== '#impressum' && location.hash !== '#dataprivacy' ? "inherit" : "none";
    impressum.style.display = location.hash === '#impressum' ? "inherit" : "none";
    dataprivacy.style.display = location.hash === '#dataprivacy' ? "inherit" : "none";

    if(window.location.hash) window.location = window.location;
}


// TODO: Replace with light-weight router
window.addEventListener("hashchange", route, false);
document.addEventListener("DOMContentLoaded", route);