
import * as firebase from "firebase/app";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBPUFLPrDCCNGLK6h9wvYLjI07s1AmPHjE",
    authDomain: "yvonne-und-johannes-heiraten.firebaseapp.com",
    databaseURL: "https://yvonne-und-johannes-heiraten.firebaseio.com",
    projectId: "yvonne-und-johannes-heiraten",
    storageBucket: "yvonne-und-johannes-heiraten.appspot.com",
    messagingSenderId: "631096718374",
    appId: "1:631096718374:web:3d271f282f4d5f2dcc22e7",
    measurementId: "G-Y3J3B8ZPZR"
};

console.log("");
// Initialize Firebase
firebase.initializeApp(firebaseConfig);