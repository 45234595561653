import "./firebase";

import "./components/YJNavigation";
import "./components/YJNavigationItem";
import "./components/YJLandingPage";
import "./components/YJAnalytics";
import "./components/YJDataprivacy";
import "./components/YJImpressum";

import "./scripts/router"
import "./scripts/register-service-worker"

import "./fonts.css";
import "./yj-style.css";
import html from "./page.html";
import notFound from "./404.html";

document.body.innerHTML = html;

console.log("***********************************************************************")
console.log("****    Built with WebComponents (LitHTML) | Hosted on Firebase    ****")
console.log("********    Ping me to get access to the GitHub repository    *********")
console.log("***********************************************************************")


