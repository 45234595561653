import { LitElement, html, css } from "lit-element";
import { classMap } from "lit-html/directives/class-map";
import "./YJNavigationToolbar";

class YJNavigation extends LitElement {
  static get properties() {
    return {
      scrollThrottle: { type: Boolean, attribute: false },
      open: { type: Boolean, reflect: false },
    };
  }

  static get styles() {
    return css`
      nav,
      ul {
        background: white;
        margin: 0;
      }

      ul {
        padding: 0;
        display: flex;
        list-style-type: none;
        justify-content: center;
        align-items: center;
      }

      .divider {
        flex-grow: 4;
      }

      yj-toolbar {
        z-index: 5;
      }

      hr {
        border: none;
        height: 1px;
        color: black;
        background-color: black;
      }

      /*** Desktop ***/
      @media all and (min-device-width: 800px) {
        yj-toolbar {
          display: none;
        }

        nav,
        ul {
          height: 100%;
        }
      }

      /*** Mobile ***/
      @media all and (max-device-width: 799px) {
        .open {
          transform: translate(0, -3px);
        }

        ul {
          flex-direction: column;
          margin: 0;
        }

        .divider {
          width: 45%;
          margin: 0 auto;
        }

        ::slotted::before {
          content: "hh";
        }

        yj-toolbar {
          position: relative;
          z-index: 2;
        }

        nav {
          width: 100%;
          position: absolute;
          z-index: 1;
          background-color: white;
          margin-top: 1px;
          transform: translate(0, -100%);
          border-bottom: 1px solid rgba(0, 0, 0, 0.8);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
          transition: all 0.4s;
        }

        nav.open {
          transform: translate(0, -3px);
        }
      }
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener("scroll", () => {
      this._monitorScrolling(this.offsetHeight);
    });
  }

  // TODO use throttling to improve performance
  _monitorScrolling(offset) {
    let fromTop = window.scrollY+1;
    let mainNavLinks = document.querySelectorAll("yj-navigation-item");
    let hash = location.hash;

    mainNavLinks.forEach((navItem) => {
      let id = navItem.link;
      let section = document.querySelector(id);
      if (
        section &&
        section.offsetTop <= fromTop &&
        section.offsetTop + section.offsetHeight - offset > fromTop
      ) {
        navItem.setAttribute("active", true);
        if (hash !== id) {
          console.log("Setting new link: " + id);
          history.pushState(null, null, id);
        }
      } else {
        navItem.removeAttribute("active");
      }
    });
  }

  _handleClick() {
    this.open = !this.open;
  }

  _handleItemClick() {
    this.open = false;
  }

  render() {
    return html`
      <!--Toolbar with Menu Icon -->
      <yj-toolbar @toggle=${this._handleClick} ?active="${this.open}"></yj-toolbar>
      <!-- Navigation Menu -->
      <nav class=${classMap({ open: this.open })}>
        <ul>
          <!-- Navigation Items -->
          <li class="divider">
            <hr />
          </li>
          <!-- Navigation Items -->
          <slot @click="${this._handleItemClick}"></slot>
          <li class="divider">
            <hr />
          </li>
        </ul>
      </nav>
    `;
  }
}
customElements.define("yj-navigation", YJNavigation);
