import { LitElement, html, css } from "lit-element";
import { classMap } from "lit-html/directives/class-map";

class YJNavigationItem extends LitElement {
  static get properties() {
    return {
      active: { type: Boolean },
      link: { type: String },
      label: { type: String },
    };
  }

  static get styles() {
    return css`

      li {
        padding: 10px;  
      }

      li a {
        font-weight: 400;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--theme-navigation-item-color);
      }
      .active{
        color:  hsl(40, 80%, 74%);
      }

      /*** Mobile ***/
      @media all and (max-device-width: 799px) {
        li {
          text-align: center;
        }
    `;
  }

  _handleClick() {
    /* this.active = true; */
    this.dispatchEvent(
      new CustomEvent("click", {
        detail: { active: this.active },
      })
    );
  }

  render() {
    return html`
      <li>
        <a
          href="${this.link}"
          @click="${this._handleClick}"
          class=${classMap({
            active: this.active,
          })}
          ><slot></slot
        ></a>
      </li>
    `;
  }
}
customElements.define("yj-navigation-item", YJNavigationItem);
