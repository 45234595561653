import { LitElement, html, css } from "lit-element";
import { classMap } from "lit-html/directives/class-map";

import firebase from "firebase/app";
import "firebase/analytics";

class YJAnalytics extends LitElement {
  static get properties() {
    return {
      isLocalhost: { type: Boolean, attribute: false },
      accepted: { type: Boolean, attribute: false },
    };
  }

  constructor() {
    // Always call super() first
    super();

    // Initialize properties
    this.accepted = document.cookie.match("cookies_accepted") != null;
    this.isLocalhost =
      location.hostname === "yvonne-und-johannes-heiraten.web.app" ||
      location.hostname === "localhost" ||
      location.hostname === "127.0.0.1" ||
      location.hostname === "0.0.0.0";

    if (this.accepted) this._enableAnalytics();
  }

  static get styles() {
    return css`
      div {
        position: fixed;
        background: white;
        right: 0;
        left: 0;
        margin: 10px auto;
        border-radius: 5px;
        bottom: 0;
        border: 1px solid black;
        box-sizing: border-box;
        width: calc(100% - 20px);
        max-width: 1000px;
        padding: 20px;
        line-height: 1.5em;
      }

      .hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.4s, opacity 0.4s linear;
      }

      input,
      button {
        color: white;
        border: 1px solid white;
        margin: 5px;
        padding: 10px;
        border-radius: 5px;
        background-color: var(--theme-color);
      }

      a {
        color: var(--theme-color);
        text-decoration: underline;
      }

      a:hover {
        text-decoration: none;
      }

      input:hover {
        cursor: pointer;
      }
    `;
  }

  _enableAnalytics(){

    if (this.isLocalhost) {
      console.log("Skipping Firebase Analytics (url=" + location.hostname + ")");
      return;
    }

    document.cookie = "cookies_accepted=" + new Date();
    firebase.analytics().logEvent("cookies_accepted");
    console.log("Enabled Firebase Analytics");
  }

  _handleClick() {
    this.accepted = true;
    this._enableAnalytics();
  }

  render() {
    return html`
    <div   class=${classMap({
      hidden: this.accepted,
    })}>
    Wir nutzen Cookies und Google Analytics, um zu sehen wer unsere Website besucht.
    Mehr dazu kannst du auch in unserer <a href="#dataprivacy">Datenschutzerklärung</a> lesen.<br />
    Bist du damit einverstanden?
    <input type="button" value="Geht klar!" @click="${
      this._handleClick
    }"></input>
    </div>
    `;
  }
}
customElements.define("yj-analytics", YJAnalytics);
