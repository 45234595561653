import { LitElement, html, css } from "lit-element";
import { classMap } from "lit-html/directives/class-map";

class YJToolbar extends LitElement {
  static get properties() {
    return {
      active: { type: Boolean},
      menuPosition: { type: String},
    };
  }

  static get styles() {
    return css`
      :host {
        background: white url(var(--theme-toolbar-background-image)) no-repeat
          cover center;
        background-image: var(--theme-toolbar-background-image);
        background-size: cover;
        background-position: center;

        height: 80px;
        /* width: 100%; */
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        text-align: center;
      }

      h1 {
        margin: 0;
        font-family: var(--theme-headings-font-family), cursive;
        font-weight: var(--theme-headings-font-weight), cursive;
        font-size: var(--theme-toolbar-font-size);
        color: var(--theme-toolbar-font-color);
      }

      * {
        box-sizing: border-box;
      }

      .hidden{
          visibility: hidden;
      }

      .nav-icon {
        width: 60px;
        height: 60px;
        padding: 10px;
        justify-content: center;
        cursor: pointer;
      }

      .nav-icon:after,
      .nav-icon:before,
      .nav-icon div {
        background-color: var(--theme-toolbar-icon-color);
        border-radius: 3px;
        content: "";
        display: block;
        height: 5px;
        margin: 7px 0;
        transition: all 0.4s ease-in-out;
      }

      .active.nav-icon:before {
        transform: translateY(12px) rotate(135deg);
      }

      .active.nav-icon:after {
        transform: translateY(-12px) rotate(-135deg);
      }

      .active.nav-icon div {
        transform: scale(0);
      }
    `;
  }

  constructor() {
    super();
    this.menuPosition = "right";
  }

  _handleClick() {
    this.dispatchEvent(
      new CustomEvent("toggle", {
        detail: { active: this.active },
      })
    );
  }

  render() {
    return html`
      <!-- <div id="toolbar"> -->
      <div
        class=${classMap({
          "nav-icon": true,
          active: this.active,
          hidden: this.menuPosition !== "left",
        })}
        @click="${this._handleClick}"
      >
        <div></div>
      </div>
      <h1>Yvonne und Johannes</h1>
      <div
        class=${classMap({
          "nav-icon": true,
          active: this.active,
          hidden: this.menuPosition !== "right",
        })}
        @click="${this._handleClick}"
      >
        <div></div>
      </div>
      <!-- </div> -->
    `;
  }
}
customElements.define("yj-toolbar", YJToolbar);
